import React from "react";
import ScrollingEffect from "../components/ScrollingEffect/ScrollingEffect";
import { Link } from "react-router-dom";
import {
  Code2,
  Rocket,
  HardDrive,
  MonitorSmartphone,
  ChevronRight,
  Linkedin,
  Mail,
} from "lucide-react";
import Footer from "../components/Footer";

function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <ScrollingEffect />
      <header className="container mx-auto px-6 py-16 md:py-24">
        <nav className="flex justify-between items-center mb-16">
          <div className="flex items-center space-x-2">
            <Code2 className="w-8 h-8 text-cyan-400" />
            <span className="text-2xl font-bold">CyberSab</span>
          </div>
          <div className="space-x-8">
            <Link
              to="/services"
              className="px-6 py-3 border border-cyan-500 hover:border-cyan-600 rounded-lg font-semibold transition-colors"
            >
              Services
            </Link>
          </div>
        </nav>

        <div className="max-w-3xl">
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            Transformez votre vision en
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-500">
              {" "}
              réalité digitale
            </span>
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Développement web sur mesure pour donner vie à vos projets avec des
            solutions modernes et performantes.
          </p>
          <Link
            to="/form"
            className="inline-flex items-center px-6 py-3 bg-cyan-500 hover:bg-cyan-600 rounded-lg font-semibold transition-colors"
          >
            Démarrer votre projet
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
        </div>
      </header>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-800/50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">Nos Services</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <MonitorSmartphone className="w-12 h-12 text-cyan-400" />,
                title: "Sites Web Responsifs",
                description:
                  "Des sites web adaptatifs offrant une expérience utilisateur optimale sur tous les appareils.",
              },
              {
                icon: <Rocket className="w-12 h-12 text-cyan-400" />,
                title: "Applications Web",
                description:
                  "Des applications web performantes et évolutives pour répondre à vos besoins spécifiques.",
              },
              {
                icon: <HardDrive className="w-12 h-12 text-cyan-400" />,
                title: "Hébergement",
                description:
                  "Un service d'hébergement fiable et sécurisé pour assurer la disponibilité et la performance de votre projet.",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="bg-gray-700/50 p-8 rounded-xl hover:transform hover:-translate-y-2 transition-all"
              >
                <div className="mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section id="expertise" className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">Technologies</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              "React.js",
              "TypeScript",
              "Node.js",
              "Tailwind CSS",
              "Next.js",
              "MongoDB",
              "PostgreSQL",
              "AWS",
            ].map((tech, index) => (
              <div
                key={index}
                className="bg-gray-700/30 p-4 rounded-lg text-center hover:bg-gray-700/50 transition-colors"
              >
                <span className="font-semibold">{tech}</span>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-gray-800/50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">
            Contactez-nous
          </h2>
          <div className="max-w-2xl mx-auto">
            <div className="flex flex-col space-y-6">
              <a
                href="mailto:cybersab@outlook.fr"
                className="flex items-center space-x-4 bg-gray-700/30 p-4 rounded-lg hover:bg-gray-700/50 transition-colors"
              >
                <Mail className="w-6 h-6 text-cyan-400" />
                <span>cybersab@outlook.fr</span>
              </a>
              <a
                href="https://www.linkedin.com/company/cybersab"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-4 bg-gray-700/30 p-4 rounded-lg hover:bg-gray-700/50 transition-colors"
              >
                <Linkedin className="w-6 h-6 text-cyan-400" />
                <span>LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
