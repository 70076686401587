import React from "react";
import { LucideIcon } from "lucide-react";

interface ProcessStepProps {
  icon: LucideIcon;
  title: string;
  description: string;
  step: number;
}

export const ProcessStep: React.FC<ProcessStepProps> = ({
  icon: Icon,
  title,
  description,
  step,
}) => {
  return (
    <div className="relative flex items-start">
      <div className="absolute top-0 left-8 h-full w-px bg-gray-700 -z-10"></div>
      <div className="flex-shrink-0">
        <div className="w-16 h-16 bg-gray-800 rounded-full flex items-center justify-center border-2 border-cyan-400">
          <Icon className="w-8 h-8 text-cyan-400" />
        </div>
      </div>
      <div className="ml-8">
        <div className="text-sm text-cyan-400 font-semibold mb-2">
          Étape {step}
        </div>
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
};
