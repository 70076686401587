import React from "react";
import { LucideIcon } from "lucide-react";

interface ServiceCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  features: string[];
  price: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  icon: Icon,
  title,
  description,
  features,
  price,
}) => {
  return (
    <div className="bg-gray-800/50 rounded-2xl p-8 hover:transform hover:scale-105 transition-all duration-300">
      <div className="w-16 h-16 bg-cyan-400/20 rounded-xl flex items-center justify-center mb-6">
        <Icon className="w-8 h-8 text-cyan-400" />
      </div>
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <p className="text-gray-400 mb-6">{description}</p>
      <div className="space-y-3 mb-8">
        {features.map((feature, index) => (
          <div key={index} className="flex items-start">
            <div className="w-5 h-5 rounded-full border-2 border-cyan-400 flex-shrink-0 mt-1"></div>
            <span className="ml-3 text-gray-300">{feature}</span>
          </div>
        ))}
      </div>
      <div className="text-center">
        <div className="text-3xl font-bold text-cyan-400 mb-4">{price}</div>
        <a
          href="/form"
          className="inline-block w-full py-3 px-6 bg-cyan-500 hover:bg-cyan-600 rounded-lg font-semibold transition-colors"
        >
          Commencer
        </a>
      </div>
    </div>
  );
};
