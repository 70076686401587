import React, { useState } from "react";
import { Send, ArrowLeft } from "lucide-react";
import { FormInput } from "./FormInput";
import { FormTextArea } from "./FormTextArea";

export const ProjectForm: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/send`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert("Une erreur est survenue lors de l'envoi de votre demande.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Une erreur est survenue.");
    }
  };

  if (submitted) {
    return (
      <div className="text-center py-16">
        <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-cyan-400/20">
          <Send className="w-8 h-8 text-cyan-400" />
        </div>
        <h2 className="text-2xl font-bold mb-4">Merci pour votre demande !</h2>
        <p className="text-gray-300 mb-8">
          Nous avons bien reçu votre message et reviendrons vers vous dans les
          plus brefs délais.
        </p>
        <button
          onClick={() => setSubmitted(false)}
          className="inline-flex items-center px-6 py-3 bg-gray-700/50 hover:bg-gray-700/70 rounded-lg font-semibold transition-colors"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Retour au formulaire
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormInput
          label="Nom"
          name="lastName"
          placeholder="Votre nom"
          required
        />
        <FormInput
          label="Prénom"
          name="firstName"
          placeholder="Votre prénom"
          required
        />
      </div>

      <FormInput
        label="Email"
        type="email"
        name="email"
        placeholder="votre@email.com"
        required
      />

      <FormInput
        label="Entreprise"
        name="company"
        placeholder="Nom de votre entreprise"
      />

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Type de projet <span className="text-cyan-400">*</span>
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            "Site vitrine",
            "E-commerce",
            "Application web",
            "Application mobile",
            "Autre",
          ].map((type) => (
            <label
              key={type}
              className="flex items-center space-x-3 p-4 bg-gray-700/50 rounded-lg cursor-pointer hover:bg-gray-700/70 transition-colors"
            >
              <input
                type="radio"
                name="projectType"
                value={type}
                className="w-4 h-4 text-cyan-400 focus:ring-cyan-400 border-gray-600 bg-gray-700"
                required
              />
              <span>{type}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Besoin d'un hébergement/nom de domaine ? <span className="text-cyan-400">*</span>
        </label>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            "Oui",
            "Non",
            "Je ne sais pas",
          ].map((type) => (
            <label
              key={type}
              className="flex items-center space-x-3 p-4 bg-gray-700/50 rounded-lg cursor-pointer hover:bg-gray-700/70 transition-colors"
            >
              <input
                type="radio"
                name="otherServices"
                value={type}
                className="w-4 h-4 text-cyan-400 focus:ring-cyan-400 border-gray-600 bg-gray-700"
                required
              />
              <span>{type}</span>
            </label>
          ))}
        </div>
      </div>

      <FormInput
        label="Budget estimé (€)"
        type="number"
        name="budget"
        placeholder="Votre budget approximatif"
      />

      <FormTextArea
        label="Description du projet"
        name="description"
        placeholder="Décrivez votre projet, vos objectifs et vos attentes..."
        required
        rows={6}
      />

      <FormTextArea
        label="Fonctionnalités souhaitées"
        name="features"
        placeholder="Listez les principales fonctionnalités que vous souhaitez..."
        rows={4}
      />

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Délai souhaité
        </label>
        <select
          name="deadline"
          className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-cyan-400 focus:border-transparent text-white"
        >
          <option value="">Sélectionnez un délai</option>
          <option value="1month">1 mois</option>
          <option value="3months">3 mois</option>
          <option value="6months">6 mois</option>
          <option value="flexible">Flexible</option>
        </select>
      </div>

      <button
        type="submit"
        className="w-full px-6 py-4 bg-cyan-500 hover:bg-cyan-600 rounded-lg font-semibold transition-colors flex items-center justify-center space-x-2"
      >
        <span>Envoyer ma demande</span>
        <Send className="w-5 h-5" />
      </button>
    </form>
  );
};
