import React from "react";
import ScrollingEffect from "../components/ScrollingEffect/ScrollingEffect";
import { Link } from "react-router-dom";
import {
  Code2,
  AppWindow,
  Rocket,
  ShoppingBag,
  PenTool,
  MessageSquare,
  Zap,
  ChevronRight,
  CheckCircle,
} from "lucide-react";
import { ServiceCard } from "../components/ServiceCard";
import { ProcessStep } from "../components/ProcessStep";
import Footer from "../components/Footer";

const services = [
  {
    icon: AppWindow,
    title: "Site Vitrine",
    description:
      "Une présence web professionnelle pour mettre en valeur votre activité",
    features: [
      "Design personnalisé",
      "Responsive design",
      "Optimisation SEO",
      "Formulaire de contact",
      "Intégration réseaux sociaux",
    ],
    price: "À partir de 190€",
  },
  {
    icon: Rocket,
    title: "Application Web",
    description: "Des solutions web sur mesure pour créer et optimiser vos processus",
    features: [
      "Architecture évolutive",
      "Interface utilisateur intuitive",
      "Tableau de bord personnalisé",
      "API RESTful",
      "Sécurité renforcée",
    ],
    price: "À partir de 990€",
  },
  {
    icon: ShoppingBag,
    title: "E-commerce",
    description:
      "Votre boutique en ligne clé en main pour développer vos ventes",
    features: [
      "Catalogue produits",
      "Paiement sécurisé",
      "Gestion des stocks",
      "Espace client",
      "Tableau d'administration",
    ],
    price: "À partir de 1990€",
  },
];

const process = [
  {
    icon: MessageSquare,
    title: "Consultation",
    description:
      "Discussion approfondie pour comprendre vos besoins et objectifs",
    step: 1,
  },
  {
    icon: PenTool,
    title: "Design & Conception",
    description: "Création de maquettes et validation de l'architecture",
    step: 2,
  },
  {
    icon: Zap,
    title: "Développement",
    description: "Développement agile avec des points réguliers",
    step: 3,
  },
  {
    icon: CheckCircle,
    title: "Livraison",
    description: "Tests, déploiement et formation à l'utilisation",
    step: 4,
  },
];

export default function Services() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <ScrollingEffect />
      <header className="container mx-auto px-6 py-8">
        <nav className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Code2 className="w-8 h-8 text-cyan-400" />
            <span className="text-2xl font-bold">CyberSab</span>
          </div>
          <div className="space-x-8">
            <Link
              to="/"
              className="text-gray-300 hover:text-cyan-400 transition-colors"
            >
              Retour à l'accueil
            </Link>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="py-12 px-6">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-8">
            Solutions Web <span className="text-cyan-400">Sur Mesure</span>
          </h1>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Des services adaptés à vos besoins pour créer une présence web
            impactante
          </p>
        </div>
      </section>

      {/* Services Grid */}
      <section className="px-6 pb-20">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 px-6 bg-gray-800/50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16">
            Notre Processus de Travail
          </h2>
          <div className="max-w-3xl mx-auto space-y-16">
            {process.map((step, index) => (
              <ProcessStep key={index} {...step} />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-6">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">
            Prêt à Concrétiser Votre Projet ?
          </h2>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Contactez-moi pour discuter de votre projet et obtenir un devis
            personnalisé
          </p>
          <Link
            to="/form"
            className="inline-flex items-center px-6 py-3 bg-cyan-500 hover:bg-cyan-600 rounded-lg font-semibold transition-colors"
          >
            Démarrer votre projet
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
}
